import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { Card, CardContent, CardMedia, Link, Typography, Button } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import styles from './leaderShip.module.css'
import { } from './leaderShip.css';

const Board = () => {
    const boardRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [heightBool, setHeightBool] = useState(false);

    const handleBoardMember = (e) => {
        console.log(e);
        setHeightBool(false);
        setSelectedIndex(e-1);
    }

    const boardTeamData = [
        {   id: 1,
            title: "Mr. Athar Shahab",
            designation: "Chairman of the Board",
            linkedIn: "httsp://linkedin.in",
            description: `Mr. Athar Shahab is serving as the Managing Director of Zuari industries
Limited, the apex company of the Adventz Group.
<br/><br/>
The Adventz Group is a 3 billion USD Indian conglomerate with over 75+
years of trust and legacy. The Group operates in four industry verticals:
Agribusiness, Engineering & Infrastructure, Real Estate & Lifestyle
and Services and gives employment to over 6,000 employees either
directly or indirectly.
<br/><br/>
Mr. Shahab also serves on the boards of several subsidiaries/JVs of Zuari
Industries Limited. Mr Shahab has more than 34 years of rich experience across project
finance, advisory, investment, project management and general
management. Mr. Shahab has made significant contribution towards private sector
partnerships in India.
<br/><br/>
Mr. Shahab has held leadership positions at reputed companies such
as L&T, Vedanta Group, IDFC, AES and ICICI Limited in the past. Mr Shahab holds a degree in Civil Engineering from VSS University of
Technology, Burla (VSSUT) and a PG Diploma in Business Management
from XLRI.`
        },
        {   id: 2,
            title: "Mr. Tribhuvan Darbari",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Mr. Tribhuvan Darbari is currently the Managing Director & CEO, Texmaco Defence Systems Pvt. Ltd. & Chief Executive, Texmaco Rail & Engineering Ltd. a flagship heavy engineering and manufacturing national player of the USD 3 Billion Adventz Group. He has around 37 years of rich & diversified experience in Corporate Strategy, Strategic Alliances & JVs, International Business Collaborations & Technology Partnerships, Commercial Operations, Project Management & conceptualizing strategic initiatives for business diversification and growth, during his assignments in Govt. and Industry, at senior levels.
<br /><br />
From 1987 onwards, he progressed from the levels of Dy. Secretary/Director/Joint Secretary in the Government of India (including under the Cabinet Secretariat, Ministry of Power, Ministry of Petroleum, Natural Gas & Chemicals, Ministry of Communications & Information Technology) & at the enterprise levels as General Manager/Vice President/Director, CEO under 5 Fortune 500 Corporations i.e. ONGC (A Govt. of India Enterprise), TATA Group, Hewlett Packard, Huawei Telecommunications & China National Petroleum Corporation (CNPC/CPP). He has contributed significantly as an active member of various important and strategic National/International Government and Corporate Committees, cutting across multiple sectors and geographies. He has been recently appointed Chairman of CII Committee on Defence and Aerospace, Eastern Region and Member of FICCI National Task Force on Defence and Aerospace.
<br /><br />
Mr. Darbari’s rich and varied experience, at senior levels, under the Ministry of Petroleum, Natural Gas, Chemicals and Petrochemicals, Pharmaceuticals and Fertilizers and under the Ministry of Energy, Department of Power, Govt. of India as well as General Manager ONGC and CEO China Petroleum (a subsidiary of China National Petroleum Corporation), has enabled him with a strategic global vision, knowledge, and he has build and nurtured valuable relationships, at the top Management levels, in the above sectors. He has also played a significant role as the Vice President of the Asia Arab Chambers of Commerce and the Director General Chindia Chambers of Commerce and Industry. His extensive and unique experience and exposure, can be gainfully utilized by the Simon India Ltd., for sustained growth of its business, both in India and Globally in the Arab World, Russia and amongst the SAARC and ASEAN Countries.
<br /><br />
Mr. Darbari Schooled at St. Columbus High School, New Delhi and after completing his Graduation & Post Graduation from St. Stephen’s College India, he has accomplished advanced management education and training from six prestigious Global Universities and Institutes in India, UK, Australia, Japan, Sweden and USA.`
        },
        {   id: 3,
            title: "Mr. Hemant Sahai",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Hemant Sahai is B. Com (Hons.) and LLB from University of Delhi. He is the Founding Partner of HSA Advocates and is responsible for the firm’s strategic growth and development. In a career spanning over three decades, he has developed a distinct focus on projects, energy & infrastructure, regulatory disputes and governance issues impacting companies and their Boards. As a member of several government committees, He frequently advises ministries and regulatory authorities on refining the legal and policy framework in India. He advises companies and their Boards on a range of aspects such as enterprise risk, corporate governance, regulatory challenges, corporate restructuring and dispute strategy. He is a member of the Boards of several organizations, including public listed companies like Akzo Nobel India Limited, MB Power (Madhya Pradesh) Limited, SAEL Industries Limited. He is also a member of International Bar Association.
            <br/><br/>
Mr. Hemant Sahai has served as an adviser to several working groups and committees formed by top government bodies/institutions including certain extra ministerial policy advisory bodies set up by the Prime Minister’s Office, Ministry of Power, Ministry of New and Renewable Energy and other government bodies, from time to time. Mr. Sahai has advised the Planning Commission, NITI Aayog and other Governmental bodies on policy issues, drafting model transaction and policy documents.
<br/><br/>
Mr. Sahai was appointed to provide legal advisory services to the High-Power Committee constituted by the Government of Gujarat to give recommendations, pursuant to deliberations with the relevant stakeholders.`
        },
        {   id: 4,
            title: "Mr. Nitin Kantak",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Mr. Nitin M. Kantak is Bachelor of Chemical Engineering from Institute of Chemical Technology (formerly UDCT), Mumbai and has over 40 years of experience in Nitrogenous and Phosphatic fertilizer industry in Plant Operations, Project Management & Commissioning, Process Engineering, and technical services. He started his career with Rashtriya Chemicals and Fertilizers Ltd, Trombay, before moving to Zuari Agro Chemicals Ltd in October 1982. He is associated with our Group and has worked in Zuari Agro Chemicals Limited since 1982. Presently he is a Whole-time Director of Mangalore Chemicals and Fertilisers Limited and Zuari Agro Chemicals Limited.`
        }
    ]

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings= {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '30px',
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 500,
        draggable: false,
        // touchMove: false,
        responsive: [
           { breakpoint: 1025,
                settings: {

                }
            },
            { breakpoint: 450,
                settings: {
                    // infinite: true,
                    draggable: true,
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    slidesToShow: 1,
                    swipeToSlide: true,
                    afterChange: (e)=>{setHeightBool(false);setSelectedIndex(e)}
                }
            },
        ]
    }
    const boardData = [
        <>
        <Card className={styles.leader_card} data-id="1" onClick={() => handleBoardMember(1)}
            sx={{borderBottom: selectedIndex === 0 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 204 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Mr. Athar Shahab"
                    className= {selectedIndex === 0 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/athar.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "",minHeight: isMobile? "84px" :""  }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 1 ? styles.fade_title : ''}`}>Mr. Athar Shahab</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 1 ? styles.fade_title : ''}`}>Chairman of the Board</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="2" onClick={() => handleBoardMember(2)}
            sx={{borderBottom: selectedIndex === 1 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 204 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Tribhuvan"
                    className= {selectedIndex === 1 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/darbari.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "",minHeight: isMobile? "84px" :""  }}>
                <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 2 ? styles.fade_title : ''}`}>Mr. Tribhuvan Darbari</Typography>
                <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 2 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="3" onClick={() => handleBoardMember(3)}
            sx={{borderBottom: selectedIndex === 2 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 204 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Mr. Akshay Poddar"
                    className= {selectedIndex === 2 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/hemant.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "",minHeight: isMobile? "84px" :"" }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 3 ? styles.fade_title : ''}`}>Mr. Hemant Sahai</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 3 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="4" onClick={() => handleBoardMember(4)}
            sx={{borderBottom: selectedIndex === 3 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 204 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Nitin"
                    className= {selectedIndex === 3 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/nitin.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "",minHeight: isMobile? "84px" :"" }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 4 ? styles.fade_title : ''}`}>Mr. Nitin Kantak</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 4 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>
    ]


    return(
        <Grid className= {styles.leadership_container} sx={{paddingBottom:"120px"}}>
            <Grid className={styles.leadership_subcontainer}>
                <Typography variant="h2" component={"h2"} className={`${styles.leader_title} ${styles.leader_bottom}`}
                    sx={{paddingLeft: isMobile ? "18px": "0px", paddingRight: isMobile ? "18px": "0px"}}
                >
                    Board of Directors
                </Typography>
                <Grid className="board-slider" 
                    sx={{paddingLeft: isMobile ? "18px": "0px", paddingRight: isMobile ? "18px": "0px", borderBottom: "1px solid #c1c1c1"}}>
                    <Slider ref={boardRef} {...settings} >
                        {boardData}
                    </Slider>
                </Grid>
                <Grid className={styles.board_container}>
                    <Grid sx={{width: {xs:"100%", sm:"33%"}}}>
                        <Typography variant="h3" component={"h3"} className={`${styles.board_team_title}`}>
                            {boardTeamData[selectedIndex].title}
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.board_team_design}`} sx={{marginBottom: "1rem"}}>
                            {boardTeamData[selectedIndex].designation}
                        </Typography>
                        <hr style={{marginBottom:"0.5rem"}} />
                        {/* <Link href={boardTeamData[selectedIndex].url} target="_blank" ><img src="/assets/images/linkedin_icon.png" alt="linkedin" /></Link> */}
                    </Grid>
                    <Grid sx={{width: {xs:"100%", sm:"67%"}}}>
                    <Typography variant="body2" component={"p"} 
                        className={`${styles.board_team_description} ${heightBool ? styles.board_team_description_height : styles.board_team_description_unheight}`}
                     dangerouslySetInnerHTML={{__html:boardTeamData[selectedIndex].description}}   
                    />
                        {!heightBool &&  !isMobile && (selectedIndex === 1 || selectedIndex === 2) && <Grid sx={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                            <Button variant="text" onClick={() => {setHeightBool(true)}} sx={{textTransform: "unset"}}>Read more <ExpandMoreIcon /> </Button>
                            </Grid>
                        }
                        {heightBool && !isMobile && (selectedIndex === 1 || selectedIndex === 2) && <Grid sx={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                            <Button variant="text" onClick={() => {setHeightBool(false)}} sx={{textTransform: "unset"}}>Read less <ExpandLessIcon /> </Button>
                            </Grid>
                        }
                        {!heightBool &&  isMobile && <Grid sx={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                            <Button variant="text" onClick={() => {setHeightBool(true)}} sx={{textTransform: "unset"}}>Read more <ExpandMoreIcon /> </Button>
                            </Grid>
                        }
                        {heightBool && isMobile && <Grid sx={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                            <Button variant="text" onClick={() => {setHeightBool(false)}} sx={{textTransform: "unset"}}>Read less <ExpandLessIcon /> </Button>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Board;

