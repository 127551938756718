import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Slider from 'react-slick';

import styles from './partners.module.css';
import { } from './partners.css'; 
import { East } from '@mui/icons-material';


import { isMobile } from 'react-device-detect';


const HomePartners = () =>{

    const navigate = useNavigate()
    // const NextArrow = props => {
    //     const { className, style, onClick } = props;
    //     return <East
    //     className={className}
    //     style={{ ...style, display: "block", right:"-7%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
    //     onClick={onClick}
    //   />
    // }
    // const PrevArrow = props => {
    //     const { className, style, onClick } = props;
    //     return <West
    //     className={className}
    //     style={{ ...style, display: "block", left:"-7%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
    //     onClick={onClick}
    //   />
    // }

    const settings = {
        dots: false,
        arrows: false,
        centerPadding: '30px',
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 450,
            settings: {
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
        ]
      }

    const partnersData = [
        <>
        <Card className={styles.partner_card} 
            sx={{ width: "100%",maxWidth: !isMobile ? 216 : "100%" , boxShadow:"unset"}}>
            <CardMedia
                component="img"
                height= {!isMobile ? "144px" : "auto"}
                image="/assets/images/partners_1.png"
                alt="green iguana"
                />
            <CardContent className={styles.partner_content}>
                <Typography className={styles.partner_second_heading} gutterBottom variant="h3" component="h3"
                dangerouslySetInnerHTML={{__html: isMobile ? "Collaborative Partnership": "Collaborative<br/>Partnership"}} />
                    
                <Typography  className={styles.partner_text} variant="body2" sx={{ color: 'text.secondary' }}>
                Unlock success with Simon India, your dedicated partner in EPMC and PCM services. We collaborate seamlessly, delivering projects with innovation, optimization and reliability across diverse sectors.
                </Typography>
            </CardContent>
        </Card>
        </>,
        <>
            <Card className={styles.partner_card} sx={{ maxWidth: !isMobile ? 216 : "100%" , boxShadow:"unset"  }}>
                <CardMedia
                    component="img"
                    height= {!isMobile ? "144px" : "auto"}
                    image="/assets/images/partners_2.png"
                    alt="green iguana"
                    />
                <CardContent className={styles.partner_content}>
                    <Typography className={styles.partner_second_heading} gutterBottom variant="h5" component="div"
                    dangerouslySetInnerHTML={{__html: isMobile ? "Engineering Talent": "Engineering<br />Talent"}} />
                    <Typography  className={styles.partner_text} variant="body2" sx={{ color: 'text.secondary' }}>
                        Our team of engineering talent brings together decades of diversified expertise, innovative solutions and a commitment to excellence, ensuring your project's success from concept to completion.
                    </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.partner_card} sx={{ maxWidth: !isMobile ? 216 : "100%" , boxShadow:"unset"  }}>
                <CardMedia
                    component="img"
                    height= {!isMobile ? "144px" : "auto"}
                    image="/assets/images/partners_3.png"
                    alt="green iguana"
                    />
                <CardContent className={styles.partner_content}>
                    <Typography className={styles.partner_second_heading} gutterBottom variant="h5" component="div" 
                        dangerouslySetInnerHTML={{__html:isMobile ? "Proven Trust": "Proven<br/>Trust"}} />
                    <Typography  className={styles.partner_text} variant="body2" sx={{ color: 'text.secondary' }}>
                        With 75+ EPC projects completed over three decades, Simon India is a name you can trust for quality, efficiency and timely delivery. We leverage a rich heritage of excellence to deliver unmatched results.
                    </Typography>
                </CardContent>
            </Card>
        </>
    ]

return (
    <Container className={styles.partner_container}>

        <Grid item sx={{width: {xs:"100%", sm: "33%"}}} >
            <Card sx={{ maxWidth: 310, width:"100%", boxShadow:"unset" }}>
                <CardContent className={styles.MuiCardContent_root}>
                    <Typography className={styles.partner_heading} gutterBottom variant="h1" component="h1"
                    dangerouslySetInnerHTML={{__html:"Trusted Engineering <br/>Partners"}}
                    />
                        
                    <Typography className={styles.partner_text} variant="body2" sx={{ marginTop:'1rem' }}>
                        Simon India’s commitment and innovative solutions have consistently driven efficiency and reliability, earning the trust of industry leaders such as Adani, SABIC, Chemanol, Paradeep Phosphates and KLJ Organics.
                        {/* At Simon India, we set the benchmark for engineering excellence across multiple sectors. Our commitment and innovative solutions has consistently driven efficiency and reliability, earning the trust of industry leaders such as Adani, SABIC, Chemanol, Paradeep Phosphates, KLJ Organics, and IFFCO. We collaborate seamlessly to deliver projects that prioritize insight, optimization, and reliability. */}
                    </Typography>
                    <Button variant='text' onClick={() => navigate("/value-proposition")} endIcon={<East />} sx={{marginTop:"1rem", paddingLeft: 0, textTransform:"none"}}>Know more </Button>
                </CardContent>
            
            </Card>
        </Grid>
        
        <Grid className= { isMobile? "partner_slider":styles.partnerSlider } sx={{width: {xs:"100%", sm: "67%"}, marginTop: isMobile ? "30px" : 0}}>
            {isMobile ? <Slider  {...settings}>
                {partnersData}
            </Slider>
            : 
                partnersData.map(item => {
                    return item;
                })
            
            }
            
        </Grid>
    </Container>
)


}

export default HomePartners;