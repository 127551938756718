import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import {Typography} from '@mui/material';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
// import PageContent from '../../components/pageContent';

import styles from './terms.module.css';

const Terms = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Terms & Conditions", url: ''}
    ]

    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])

    const details = "The following Terms and Conditions, when read in conjunction with the Privacy Policy, serve as a binding contract between you and Simon India Limited (“the Company”). You are presumed to have accepted all the following Terms and Conditions by visiting, viewing, or accessing any of the services or information produced, collected, collated, or provided by us through this website ____________. Please carefully read these Terms before using this website since you agree to be bound by them. Please refrain from using this website if you do not agree to accept these terms and conditions.";

    return (
        <Grid sx={{width: "100%"}}>
            <PageBanner title="Terms & Conditions" bannerPath={bannerPath}
          />
          <Grid className={styles.page_container}>
            <Grid className={styles.page_subcontainer}>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{__html:details}}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Disclaimer</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"This website may contain forward-looking statements regarding the future business prospects and profitability of Simon India Limited. These statements are subject to various risks and uncertainties, and actual results may significantly differ from those projected. Factors that could impact the company's operations include downturns in the infrastructure sector, substantial changes in India’s political and economic landscape, tax regulations, labour relations, and litigation. Simon India Limited does not commit to announcing when these forward-looking statements become materially inaccurate or to updating any such statements made periodically by or on behalf of the company."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Retention of Data</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`The Company retains Data for as long as necessary for the use of our services or to provide access to and use of our website, or for other essential purposes such as complying with our legal obligations, resolving disputes, enforcing our agreements and as long as processing and retaining your Data is necessary for our legitimate interests. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly.
<br/><br/>
Even if we delete your Data, including on account of exercise of your right, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Terms and Conditions of Use</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`The information provided on this site is presented in good faith and intended for general informational purposes. Users should not rely on it for specific decisions, and no representations or warranties are made regarding its accuracy or completeness. The information on this website does not constitute an invitation to invest in Simon India Limited, its affiliates, or its group.
<br/><br/>
Users are granted a limited license to use the content of this website for personal, non-commercial purposes. No other rights, titles, or interests are granted to users by implication or otherwise. Simon India Limited is not liable for any loss, damage, or expense incurred by users as a result of using this website.
<br/><br/>
This site may contain links to external websites, which are provided solely for user convenience. Simon India Limited does not endorse or represent these third parties and is not liable for any damages, losses, or expenses resulting from the use of or access to these linked contents.
<br/><br/>
Simon India Limited reserves the right to change, revise, modify, add, or remove content from this website at any time without prior notice.
<br/><br/>
We request the user to carry out complete due diligence about the services and the related information before placing its reliance on the website content.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>User Conduct</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of the website by any third party. You agree not to use the website for any illegal or unauthorized purposes, including but not limited to, unauthorized access to, use, or modification of data, unauthorized commercial advertising or solicitations and unauthorized downloading or distribution of copyrighted material. Any breach of the said conduct shall entail initiation of legal proceedings by Simon India Limited and compensation may be sought for the related damages."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Privacy Policy</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"We will use your personal information in accordance with our commitment to privacy. Please review our Privacy Policy, which also governs your visit to this website, for additional information about our policies regarding the collection, use and disclosure of your personal information."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Indemnity</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"You agree to indemnify, defend and hold harmless the website and its affiliates, directors, officers, employees and agents from and against any and all claims, damages, losses, liabilities and expenses, arising out of or in connection with your use of this website, or any breach of these terms and conditions. Indemnification provisions encompass, but are not limited to, any claims made by third parties related to your use of this website, the content you provide, as well as any violations of applicable laws or regulations."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Intellectual Property</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website and its content, including but not limited to, text, graphics, logos, images and software, are the property of Simon India Limited or its licensors and are protected by applicable intellectual property laws. You may not use any content or materials from the website for commercial purposes without our prior written consent.
                    <br/><br/>
                    We respect the intellectual property rights of others, and we expect our users to do the same. Any content or materials posted on our platform must comply with applicable laws and regulations and must not infringe on the intellectual property rights of others. Any breach or incident of the said infringement shall entail initiation of legal proceedings by Simon India Limited and compensation may be sought for the related damages.
                    `
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Disclaimer of Warranty</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website and its content are provided "as is" and "as available" without warranties of any kind, either express or implied, including but not limited to, warranties of merchantability, fitness for a particular purpose and non-infringement.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Limitation of Liability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"In no event shall the Company be liable for any damages, including but not limited to, direct, indirect, special, incidental, or consequential damages, arising out of the use of or inability to use the website, or the performance of the products or services offered through the website."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Governing Law and Jurisdiction</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"These terms and conditions are to be interpreted in accordance with Indian laws. In the case of any dispute or claim relating to these terms and conditions, the dispute or claim shall be subject to the exclusive jurisdiction of the courts at Delhi, India and no other courts shall have any jurisdiction in the matter."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Changes to Terms & Conditions</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"We reserve the right to modify these terms & conditions at any time without notice. Your continued use of the website after any changes to these terms & conditions constitutes your acceptance of the modified terms & conditions."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Severability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Whenever possible, each section of this Privacy Policy shall be interpreted in a manner so as to be valid under applicable law. However, in the event any provision is held to be prohibited, or invalid, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or other remaining provisions of this Privacy Policy."
                }}
                />
            </Grid>
            <Grid className={styles.page_subcontainer} sx={{backgroundColor:"#ECF2F6", marginTop:0, marginBottom:"", padding:"4rem"}}>
            <Typography variant="body2" component={"p"} className={styles.page_content} sx={{width: "100%", fontSize: "1.25rem !important", fontWeight: 600, marginBottom:"1rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Head - IT, Simon India Ltd."
                }}
                />
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom: "0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Global Business Park, Tower A, 8th Floor, Sector-26, MG Road, Gurgaon-122002, HARYANA, INDIA <br/>Phone: 011-42602222, 26943004/05"
                }}
                />
            </Grid>
            <AdvantageC />
        </Grid>
            
</Grid>
    )
}

export default Terms;