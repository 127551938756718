import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import {Typography} from '@mui/material';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
// import PageContent from '../../components/pageContent';

import styles from './privacy.module.css';

const Privacy = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Privacy Policy", url: ''}
    ]

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])

    const details = `Simon India Limited having its Corporate Office/ Registered Office at __Gurgaon___  (“SIL”, “Company”, “we”, “us” or “our”) is managing and operating the website ____________ (“website”) for the users of the website (“User” or “Users” or “you” or “your”).  This Privacy Policy together with terms & conditions explains how the website collects, uses, discloses, processes, deals, handles, shares, transfers and safeguards any personal information you provide when using this website. At Simon India, we are committed to protecting your privacy.
<br/><br/>
When you visit this site and provide your information in order to use our service, you agree to be bound by the terms and conditions of this Privacy Policy. We shall not use the User’s information in any manner except as provided under this Privacy Policy. Please do not access the site if you disagree with this.
<br/><br/>
This document is an electronic record and is governed by the provisions under the Information Technology Act, 2000 and rules made thereunder, as may be applicable, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.`;

    return (
        <Grid sx={{width: "100%"}}>
            <PageBanner title="Privacy Policy" bannerPath={bannerPath}
          />
          <Grid className={styles.page_container}>
            <Grid className={styles.page_subcontainer}>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{__html:details}}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Definitions</Typography>
                <ul>
                    <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Cookies:</span> a small file placed on your device by our website or mobile application when you either visit or use certain features of our website or mobile application. A cookie generally allows a website to remember your actions or preference for a certain period of time.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Data:</span> includes non-personal information, personal information and sensitive personal information about you, which either directly or indirectly in combination with other information, could allow you to be identified when you visit our stores, website and/or mobile application.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Data Protection Laws:</span> The Information Technology Act, 2000, Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, Digital Personal Data Protection Act, 2023 and/ or any applicable law(s), regulation(s) or rule(s) relating to the processing of Data for the time being in force or as amended from time to time.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Partners:</span> select third parties with whom we have contracts for the businesses including third party carriers and their agents`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    Service Providers includes entities to whom we shall disclose your Data in order to process information for a specific purpose.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} 
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Simon India Limited:</span> a company incorporated in India whose registered office is at Birla Mill Complex, P.O. Birla Lines, G.T. Road, near Clock Tower, Delhi-110007`
                }}
                /></li>
                </ul>
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Information Collection & Use</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`We may collect information from you when you register on our website. This information may include your name, email address, phone/ mobile number. We may automatically receive your computer's internet protocol (IP) address when you browse the website, which gives us information about your operating system and browser.
<br/><br/>
We may collect, use or process your information including Personal Information and Sensitive Personal Data or Information for the following purposes:`
                }}
                />
                <ul>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`For creating and giving you access to your registered account on the website.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`To inform you about our services, offers, updates, upcoming events, including providing you information in relation to technical notices, security alerts.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`For internal analytical and research purposes such as auditing, data analysis and research to improve our services and customer communications.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`To meet any legal or regulatory requirement or comply with a request from any governmental or judicial authority.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`To resolve any request, dispute, grievance or complaint raised by you in relation to your use of the website.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content}
                        dangerouslySetInnerHTML={{
                            __html:`To detect or monitor any fraudulent or illegal activity on the website.`
                        }}
                    />
                    </li>
                </ul>


                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Disclosure of Information</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`We may also disclose your information when such disclosure is requisitioned under any law or when we, in our sole discretion, deem it necessary in order to protect our rights or the rights of other Users, to prevent harm to persons or property, to fight fraud and credit risk.
<br/><br/>
We may also disclose or transfer your information to any third party as a part of reorganization or a sale of the assets, division or transfer of a part or whole of us. We shall ensure that third party to which we transfer or sell our assets will have appropriate confidentiality and security measures, at least as protective as those described in this Privacy Policy, to handle your Personal Information. You will have the opportunity to opt out of any such transfer if the new entity's planned processing of your information differs materially from that set forth in this Privacy Policy.
<br/><br/>
To further understand how these providers will treat your personal information, we advise you to read the respective privacy policies of the third-party service providers.
<br/><br/>
While we take best efforts to ensure that your information including Personal Information, Financial Information and Sensitive Personal Information or Data is duly protected by undertaking security measures prescribed under applicable laws, you are strongly advised to exercise reasonable discretion while providing Personal Information or Financial Information while using the services given that the Internet is susceptible to security breaches for which Simon India shall not be liable.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Cookies</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`Cookies are small text files that are stored on your device by the website. They contain information about the user's preferences and actions on the website.
<br/><br/>
We use cookies to enhance your browsing experience by remembering your preferences and settings and to analyse overall user trends and to help us improve our services.
<br/><br/>
Cookies will improve website performance by storing information about your previous visits which will help in faster loading and improve your overall user experience. It can also provide valuable insights into user behaviour by tracking which pages you visit and how long you spend on each page, helping us identify areas for improvement and make data-driven decisions.
<br/><br/>
By using our website, you consent to our use of cookies in accordance with our privacy policy. You have the option to manage and delete cookies from your browser settings. However, disabling cookies may limit the functionality of our website and it may impair, degrade or restrict access to certain areas of our website.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Third-Party Links</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"Our website may contain links to third-party websites. We shall not be held responsible for the privacy policies or practices of the other third-party websites. We encourage you to read the privacy policy of any such website you visit."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Indemnity</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"You agree to indemnify, defend and hold harmless the website and its affiliates, directors, officers, employees and agents from and against any and all claims, damages, losses, liabilities and expenses, arising out of or in connection with your use of this website, or any breach of these terms and conditions. Indemnification provisions encompass, but are not limited to, any claims made by third parties related to your use of this website, the content you provide, as well as any violations of applicable laws or regulations."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Intellectual Property</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website and its content, including but not limited to, text, graphics, logos, images and software, are the property of Simon India Limited or its licensors and are protected by applicable intellectual property laws. You may not use any content or materials from the website for commercial purposes without our prior written consent.
                    <br/><br/>
                    We respect the intellectual property rights of others, and we expect our users to do the same. Any content or materials posted on our platform must comply with applicable laws and regulations and must not infringe on the intellectual property rights of others. Any breach or incident of the said infringement shall entail initiation of legal proceedings by Simon India Limited and compensation may be sought for the related damages.
                    `
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Disclaimer of Warranty</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website and its content are provided "as is" and "as available" without warranties of any kind, either express or implied, including but not limited to, warranties of merchantability, fitness for a particular purpose and non-infringement.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Limitation of Liability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"In no event shall the Company be liable for any damages, including but not limited to, direct, indirect, special, incidental, or consequential damages, arising out of the use of or inability to use the website, or the performance of the products or services offered through the website."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Governing Law and Jurisdiction</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"These terms and conditions are to be interpreted in accordance with Indian laws. In the case of any dispute or claim relating to these terms and conditions, the dispute or claim shall be subject to the exclusive jurisdiction of the courts at Delhi, India and no other courts shall have any jurisdiction in the matter."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Changes to Terms & Conditions</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"We reserve the right to modify these terms & conditions at any time without notice. Your continued use of the website after any changes to these terms & conditions constitutes your acceptance of the modified terms & conditions."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content}>Severability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Whenever possible, each section of this Privacy Policy shall be interpreted in a manner so as to be valid under applicable law. However, in the event any provision is held to be prohibited, or invalid, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or other remaining provisions of this Privacy Policy."
                }}
                />
            </Grid>
            <Grid className={styles.page_subcontainer} sx={{backgroundColor:"#ECF2F6", marginTop:0, marginBottom:"", padding:"4rem"}}>
            <Typography variant="body2" component={"p"} className={styles.page_content} sx={{width: "100%", fontSize: "1.25rem !important", fontWeight: 600, marginBottom:"1rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Head - IT, Simon India Ltd."
                }}
                />
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom: "0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Global Business Park, Tower A, 8th Floor, Sector-26, MG Road, Gurgaon-122002, HARYANA, INDIA <br/>Phone: 011-42602222, 26943004/05"
                }}
                />
            </Grid>
        <AdvantageC />
        </Grid>
            
</Grid>
    )
}

export default Privacy;